.swiper {
    width: 100%;
    height: 100%;
    /* padding-top: 50px;
    padding-bottom: 50px; */
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-slide:not(.swiper-slide-active) {
    opacity: 0.5;
    transition: opacity 0.3s ease;
}